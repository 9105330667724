<template>
  <v-dialog v-model="d_del" max-width="400" persistent>
    <v-card v-if="pocket" class="rounded_xx py-5">
      <div class="d-flex flex-column align-center">
        <div class="ico_alert">
          <v-icon size="30" color="white">mdi-trash-can</v-icon>
        </div>
        <p class="font-weight-bold my-2">Delete this data?</p>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          @click="$emit('close')"
          class="pa-6 text-capitalize mr-2"
          color="red"
          outlined
          >Cancel</v-btn
        >
        <v-btn @click="deleteData" class="pa-6 text-capitalize" color="red" dark
          >Delete</v-btn
        >
      </div>
      <v-overlay :value="loading" color="white" opacity="1" absolute>
        <div class="d-flex align-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-3"
          ></v-progress-circular>
          <p class="font-weight-bold ma-0 blue--text">Loading...</p>
        </div>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "deleteData",
  props: ["d_del", "pocket"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteData() {
      this.loading = true;
      let data = {
        path: this.pocket.path,
        body: {
          id: this.pocket.id,
        },
      };
      this.$store
        .dispatch("postApi", data)
        .then((data) => {
          this.loading = false;
          this.$emit("refetch", data.message);
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.ico_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f44336;
}
</style>
