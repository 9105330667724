<template>
  <v-row justify="start">
    <v-dialog v-model="d_post" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat v-if="pocket">
          <span class="headline">{{ pocket.path }} Biro</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="py-3 px-5" v-if="pocket">
          <div>
            <v-text-field
              label="Nama"
              v-model="pocket.nama"
              required
              outlined
              dense
              rounded
            ></v-text-field>
            <v-text-field
              label="Link"
              required
              v-model="pocket.link"
              outlined
              dense
              rounded
            ></v-text-field>
            <v-overlay :value="loading" color="white" opacity="1" absolute>
              <div class="d-flex align-center">
                <v-progress-circular
                  indeterminate
                  color="puple"
                  class="mr-3"
                ></v-progress-circular>
                <p class="font-weight-bold ma-0 puple--text">Loading...</p>
              </div>
            </v-overlay>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="createData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "postData",

  props: ["d_post", "pocket"],
  computed: {},
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    createData() {
      this.loading = true;
      let data = {
        body: {
          nama: this.pocket.nama,
          link: this.pocket.link,
        },
        path: `public/biro/${this.pocket.path}`,
      };
      if (this.pocket.path == "update") {
        data.body["id"] = this.pocket.id;
      }

      this.$store.dispatch("postApi", data).then((data) => {
        this.loading = false;
        this.$emit("close");
        this.$emit("refetch");
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000,
        });
      });
    },
  },
};
</script>

<style></style>
