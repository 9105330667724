<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card _60-w" :loading="loading" min-height="150px">
        <v-toolbar flat dense> Biro </v-toolbar>
        <v-card-title class="pa-3">
          <v-btn outlined rounded color="blue" @click="createBiro"
            ><v-icon>mdi-plus</v-icon> Add Biro</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            solo
            rounded
            hide-details
          ></v-text-field>
        </v-card-title>

        <div class="mt-10" v-if="dataSet.length">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="dataSet"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  @click.prevent="updateBiro(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  @click.prevent="deleteItem(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :total-visible="7"
              @input="fetchData"
              :length="last_page"
            ></v-pagination>
          </div>
        </div>
        <div class="pa-2">
          <post-biro
            :d_post="d_post"
            :pocket="pocket"
            @close="d_post = false"
            @refetch="fetchData"
          />
          <DeleteData
            :pocket="pocket"
            @close="d_del = false"
            :d_del="d_del"
            @refetch="fetchData"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import PostBiro from "../../components/Admin/Modal/postBiro.vue";
import DeleteData from "../../components/Admin/Modal/deleteData.vue";

export default {
  name: "BiroManagement",

  components: { PostBiro, DeleteData },

  computed: {
    ...mapState({}),
  },

  data() {
    return {
      dialogAdd: false,
      dialogUpdate: false,
      dataSet: [],
      d_del: false,
      last_page: null,
      campus: null,
      d_post: false,
      pocket: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama" },
        { text: "ACTIONS", value: "actions" },
      ],
      page: 1,
      perPage: 10,
    };
  },

  mounted() {
    this.fetchData(); //get all data campus when load page
  },

  methods: {
    fetchData() {
      //get all data campus fucntion
      this.loading = true;
      let data = {
        path: `public/biro`,
      };
      this.$store.dispatch("getApi", data).then((data) => {
        console.log(data);
        this.dataSet = data.data;
        this.last_page = data.last_page;
        this.loading = false;
      });
    },
    createBiro() {
      this.pocket = {
        nama: "",
        link: "",
        path: "create",
      };
      this.d_post = true;
    },

    updateBiro(item) {
      this.pocket = {
        id: item.id,
        nama: item.nama,
        link: item.link,
        path: "update",
      };
      this.d_post = true;
    },

    deleteItem(item) {
      this.pocket = {
        id: item.id,
        path: `public/biro/delete`,
      };
      this.d_del = true;
    },

    closeDialog() {
      //close dialog
      this.dialogAdd = false; //set variable dialog to false
      this.dialogUpdate = false; //set variable dialog to false
      this.fetchData(); //and refetch data campus
    },
  },
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
</style>
